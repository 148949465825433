
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/*colors
light p: #9E78E3
dark p: #6F43A3
light y: #FBF891
dark y: #F3E92F
cyan: #2DA29A */


/*fonts
font-family: 'Pacifico', cursive;
font-family: 'Petrona', serif;
font-family: 'Special Elite', cursive;*/

/*------HEADER------*/

.homeName {
    /* ashten l. bartz: */
    font-family: 'Pacifico', cursive;
    font-size: 5vw;
    padding: -5%;
    color: #9E78E3;
    display: flex;
    justify-content: center;
}

.navBar {
  background-color: #DCD1EE;
  color: black;
}

/*Links*/
a:link {
    color: #6F43A3;
    text-decoration: none;
}

a:visited {
    color: #6F43A3;
    text-decoration: none;
}

a:hover {
    color: black;
}

a:active {
    color: black;
}

.centered {
  justify-content: center;
}

/*HOME*/

.homeBody {
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
}

.homeBody h1 {
  font-family: 'Pacifico', cursive;
  font-size: 3vw;
}

.homeBody h2 {
  font-family: 'Roboto', serif;
  font-size: 1.7vw;
}

.stylized {
  font-family: 'Special Elite', sans-serif;
  color: #6F43A3;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.row {
  display: flex;
}

/*PROJECTS*/

.project {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.projectImg {
  width: 900px;
  height: 140px;
}

.projectImgContainer {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.project h1 {
  text-align: center;
}

.project h2 {
  margin-bottom: -7px;
}

.projectCol {
  padding: 0% 1%;
  margin-left: auto;
  margin-right: auto;
}

/*JOURNEY*/

.pptImg {
  width: 700px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

.gifImg {
  width: 25%;
  text-align: center;
  padding: 5px;
}

.gif {
  width: 100%;
}

/*WW*/
.wwTable, th, td {
  border: 1px solid black;
}

.noLines, th, td {
  border: none;
}

.icon {
  width: 70px;
}

/*FOVI*/
.chart {
    width: 500px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
}

/*ABOUT*/

.aboutBody {
  margin-left: 100px;
  margin-right: 100px;
}

.aboutBody h1 {
  text-align: center;
  font-family: 'Pacifico', cursive;
  font-size: 3vw;
  margin-top: 3%;
}

.aboutBody h2 {
  font-family: 'Roboto', serif;
  font-size: 1.5vw;
}

.aboutCol {
  padding: 0% 3%;
}

.aboutPix {
  margin-top: 3%;
}

.aboutImgContainer {
  width: 130px;
}

.aboutImg {
  width: 100%;
}

footer {
  text-align: center;
  font-size: 1vw;
  background-color: #DCD1EE;
  padding: 1px;
}
